import React, { useState, useEffect } from 'react';
import { Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Stack, Text, NumberInput, NumberInputField, NumberInputStepper, NumberDecrementStepper, NumberIncrementStepper, ModalFooter, Button } from '@chakra-ui/react';

interface FormModalProps {
    isOpen: boolean
    onClose: () => void
    onSave: (values: any) => void,
    set?: number
    data: any
}

export const FormModal = (props: FormModalProps) => {
    const { isOpen, onClose, onSave, data } = props;
    const [formValues, setFormValues] = useState({
        pullups: data.pullups,
        pushups: data.pushups,
        squats: data.squats
    });

    const handleChange = (value: any, field: string) => {
        setFormValues(prev => ({
            ...prev,
            [field]: Number(value)
        }));
    }

    useEffect(() => {
        if (isOpen) {
            setFormValues({ pullups: data.pullups, pushups: data.pushups, squats: data.squats });
        }
    }, [isOpen])

    return (
        <Modal blockScrollOnMount={true} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent marginX={2}>
                <ModalHeader>Serie {data.index}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Stack spacing={3}>
                        <Flex alignItems="center" justifyContent="space-between">
                            <Text>Dominadas</Text>
                            <NumberInput min={0} maxW={200} flex={1} value={formValues.pullups} onChange={(e) => handleChange(e, 'pullups')}>
                                <NumberInputField />
                                <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                </NumberInputStepper>
                            </NumberInput>
                        </Flex>
                        <Flex alignItems="center" justifyContent="space-between">
                            <Text>Flexiones</Text>
                            <NumberInput min={0} maxW={200} flex={1} value={formValues.pushups} onChange={(e) => handleChange(e, 'pushups')}>
                                <NumberInputField />
                                <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                </NumberInputStepper>
                            </NumberInput>
                        </Flex>
                        <Flex alignItems="center" justifyContent="space-between">
                            <Text>Sentadillas</Text>
                            <NumberInput min={0} maxW={200} flex={1} value={formValues.squats} onChange={(e) => handleChange(e, 'squats')}>
                                <NumberInputField />
                                <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                </NumberInputStepper>
                            </NumberInput>
                        </Flex>
                    </Stack>
                </ModalBody>

                <ModalFooter>
                    <Button variant="ghost" mr={2} onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button backgroundColor="#00e975" color="#fff" onClick={() => onSave({ ...formValues, set: data.index, id: data.id })}>
                        Guardar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};