/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import RESTClient from "../../services/api-service";
import Session from "../../models/session";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
  Flex,
  Button,
  useToast,
} from "@chakra-ui/react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

interface MurphTableProps {}

export const MurphTable = (props: MurphTableProps) => {
  const [sessions, setSessions] = React.useState<Session[]>([]);
  const toast = useToast();

  React.useEffect(() => {
    getSessions();
    const channel = subscribeToInserts();

    return () => {
      channel.unsubscribe();
    };
  }, []);

  const getSessions = async () => {
    const { data } = await RESTClient.from("sessions")
      .select()
      .order("day", { ascending: false });
    setSessions(data as any);
  };

  const subscribeToInserts = () => {
    return RESTClient.channel("insert-channel")
      .on(
        "postgres_changes",
        { event: "INSERT", schema: "public", table: "sessions" },
        (payload: any) => {
          setSessions((prev) => [payload.new, ...prev]);
        }
      )
      .subscribe();
  };

  const handleRemove = async (id: number) => {
    await RESTClient.from("sessions")
      .delete()
      .eq("id", id)
      .then((e) => {
        getSessions();
        toast({
          title: "Entranamiento borrado.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <Accordion allowToggle>
      {sessions.map((v, i) => (
        <AccordionItem key={v.id}>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              {new Date(v.day).toDateString()}
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel p={0}>
            <TableContainer>
              <Table variant="striped" colorScheme="blackAlpha" size="sm">
                <TableCaption m={0}>
                  <Flex gap={2} justifyContent="center">
                    <span>{v.exercises.total.sets} Series</span>
                    <span>{v.exercises.total.pullups} Dominadas</span>
                    <span>{v.exercises.total.pushups} Flexiones</span>
                    <span>{v.exercises.total.squats} Sentadillas</span>
                  </Flex>
                </TableCaption>
                <TableCaption>
                  <Button
                    backgroundColor="#00e975"
                    onClick={() => handleRemove(v.id)}
                  >
                    <FontAwesomeIcon icon={faTrash} color="white" />
                  </Button>
                </TableCaption>
                <Thead>
                  <Tr>
                    <Th>Dominadas</Th>
                    <Th>Flexiones</Th>
                    <Th>Sentadillas</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {sessions[i].exercises.sets.map((rep, j) => (
                    <Tr key={v.id + "-" + j}>
                      <Td>{rep.pullups}</Td>
                      <Td>{rep.pushups}</Td>
                      <Td>{rep.squats}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};
