import React, { useState, useEffect } from 'react';
import { Box, Button, Divider, Flex, Text } from '@chakra-ui/react';
import { faPause, faPlay, faStop } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Timer = () => {
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [isRunning, setIsRunning] = useState(false);

    useEffect(() => {
        let interval: any;
        if (isRunning) {
            interval = setInterval(() => {
                if (seconds === 59) {
                    setMinutes(prevMinutes => prevMinutes + 1);
                    setSeconds(0);
                } else {
                    setSeconds(prevSeconds => prevSeconds + 1);
                }
            }, 1000);
        } else {
            clearInterval(interval);
        }
        return () => {
            clearInterval(interval);
        };
    }, [isRunning, seconds]);

    const handleStartStop = () => {
        setIsRunning(prevIsRunning => !prevIsRunning);
    };

    const handleReset = () => {
        setMinutes(0);
        setSeconds(0);
        setIsRunning(false);
    };

    return (
        <React.Fragment>
            <Flex alignItems="center" justifyContent="space-evenly" padding={4} >
                <Text fontSize={22}>{String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}</Text>
                <Flex gap={4}>
                    <Button onClick={handleStartStop}>{isRunning ? <FontAwesomeIcon icon={faPause} /> : <FontAwesomeIcon icon={faPlay} />}</Button>
                    <Button onClick={handleReset}><FontAwesomeIcon icon={faStop} /></Button>
                </Flex>
            </Flex>
        </React.Fragment>
    );
}