import React, { useRef, useState, useEffect } from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  Select,
} from "@chakra-ui/react";
import Session from "../../models/session";
import RESTClient from "../../services/api-service";

export const MurphChart = () => {
  const [sessions, setSessions] = useState<Session[]>([]);
  const [selectValue, setSelectValue] = useState("training");
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const [options, setOptions] = useState<Highcharts.Options>({});

  const getSessions = async () => {
    const { data } = await RESTClient.from("sessions")
      .select()
      .order("day", { ascending: false });
    setSessions(data as any);
    setOptions({
      title: {
        text: "",
      },
      xAxis: {
        type: "datetime",
      },
      yAxis: {
        title: {
          text: "Repeticiones",
        },
        max: 300,
        min: 0
      },
      series: [
        {
          type: "line",
          name: "Dominadas",
          data: data?.map((v) => ({
            x: new Date(v.day).getTime(),
            y: v.exercises.total.pullups,
          })),
        },
        {
          type: "line",
          name: "Flexiones",
          data: data?.map((v) => ({
            x: new Date(v.day).getTime(),
            y: v.exercises.total.pushups,
          })),
        },
        {
          type: "line",
          name: "Sentadillas",
          data: data?.map((v) => ({
            x: new Date(v.day).getTime(),
            y: v.exercises.total.squats,
          })),
        },
      ],
      credits: {
        enabled: false,
      },
    });
  };

  const getWeight = () => {
    setOptions({
      title: {
        text: "",
      },
      xAxis: {
        type: "datetime",
      },
      yAxis: {
        title: {
          text: "Kilogramos",
        },
        max: 110,
        min: 60

      },
      series: [
        {
          type: "line",
          name: "Peso",
          data: [
            { x: new Date("2023-01-01").getTime(), y: 83 },
            { x: new Date("2023-02-15").getTime(), y: 81.1 },
            { x: new Date("2023-03-15").getTime(), y: 79.8 },
            { x: new Date("2023-09-05").getTime(), y: 76.4 },
          ],
        },
        {
          type: "line",
          name: "Objetivo",
          data: [
            { x: new Date("2023-01-01").getTime(), y: 85, dataLabels: { enabled: true } },
            { x: new Date("2023-09-05").getTime(), y: 85 },
          ],
          marker: {
            enabled: false
          }
        },
      ],
      credits: {
        enabled: false,
      },
    });
  };

  useEffect(() => {
    switch (selectValue) {
      case "training":
        getSessions();
        break;
      case "weight":
        getWeight();
        break;

      default:
        break;
    }
  }, [selectValue]);

  return (
    <Box py={2}>
      <Select p={2} onChange={(e) => setSelectValue(e.target.value)}>
        <option value="training">Entrenamiento</option>
        <option value="weight">Peso</option>
        {/* <option value="progress">Progreso</option> */}
      </Select>
      {selectValue !== "progress" && (
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartComponentRef}
        />
      )}
      {selectValue === "progress" && (
        <CircularProgress
          value={40}
          size="120px"
          thickness="4px"
          color="#00e975"
        >
          <CircularProgressLabel>40%</CircularProgressLabel>
        </CircularProgress>
      )}
    </Box>
  );
};
