import React from "react";
import "./App.css";
import { Form } from "./components/form/Form";
import { NavBar } from "./components/nav/Nav";
import { MurphTable } from "./components/table/MurphTable";
import {
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { MurphChart } from "./components/chart/MurphChart";

function App() {

  return (
    <div className="App">
      <NavBar />
      <Tabs size="lg" isFitted variant="unstyled">
        <TabList>
          <Tab>Series</Tab>
          <Tab>Tabla</Tab>
          <Tab>Gráfica</Tab>
        </TabList>
        <TabIndicator height="2px" bg="#00e975" borderRadius="1px" />
        <TabPanels>
            <TabPanel padding={0}>
              <Form />
            </TabPanel>
            <TabPanel padding={0}>
              <MurphTable />
            </TabPanel>
            <TabPanel padding={0}>
              <MurphChart />
            </TabPanel>
          </TabPanels>
      </Tabs>
    </div>
  );
}

export default App;
