import {
    Badge,
    Box,
    Button,
    Card,
    CardBody,
    Divider,
    Flex,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    SimpleGrid,
    Text,
    useDisclosure,
    useToast
} from "@chakra-ui/react";
import { faArrowDown, faFlagCheckered } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import RESTClient from "../../services/api-service";
import { Timer } from "../timer/Timer";
import { FormModal } from "./FormModal";

interface FormProps { }

export const Form = (props: FormProps) => {
    const [sets, setSets] = useState<any>([]);
    const [formSets, setFormSets] = useState(0);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [modalData, setModalData] = useState<any>(0);
    const [disabled, setDisabled] = useState({ setDown: false, finish: false })
    const toast = useToast();

    const getSnapshot = async () => {
        const { data } = await RESTClient.from("snapshot")
            .select()
            .order("set", { ascending: true });

        if (data?.length) {
            setSets(data);
            setFormSets(data.length);
            setDisabled(prev => ({ ...prev, setDown: true }));
            return;
        }
        setDisabled(prev => ({ finish: true, setDown: false }));
    };

    const calculateRepsForSet = (totalReps: number, sets: number, index: number) => {
        const reps = Math.trunc(totalReps / sets);
        const rest = totalReps % sets;
        if (rest) {
            return index <= rest ? (reps + 1) : reps;
        }
        return reps;
    }

    const displayDownSetsOnPage = async () => {
        if (formSets <= 0 || formSets > 50) {
            return;
        }

        await RESTClient.from("snapshot").delete().gt("id", 0);
        const setsSnapshot = [...Array(formSets)].map((v, index) => ({
            day: new Date(),
            set: index + 1,
            pullups: calculateRepsForSet(100, formSets, (index + 1)),
            pushups: calculateRepsForSet(200, formSets, (index + 1)),
            squats: calculateRepsForSet(300, formSets, (index + 1)),
        }));
        const { data } = await RESTClient.from("snapshot")
            .insert(setsSnapshot)
            .select();
        setSets(data);
        setDisabled(prev => ({ finish: false, setDown: true }));
    };

    const handleOpenModal = (data: any) => {
        setModalData(data);
        onOpen();
    }

    const handleSaveModal = async (values: any) => {
        setSets((prev: any[]) => {
            const index = prev.findIndex(v => v.set === values.set)
            prev[index] = { ...prev[index], ...values }
            return prev;
        });
        
        await RESTClient.from('snapshot')
            .update({ pullups: values.pullups, pushups: values.pushups, squats: values.squats })
            .eq('id', values.id);
            
        onClose();
    }

    const handleFinish = async () => {
        
        const total = sets.reduce((acc: any, current: any, index: number) => ({
            pullups: acc.pullups + current.pullups,
            pushups: acc.pushups + current.pushups,
            squats: acc.squats + current.squats,
            sets: index + 1
        }));

        await RESTClient
            .from('sessions')
            .insert([
                { 
                    day: new Date(),
                    user: "Jose",
                    exercises: {
                        total,
                        sets: sets.map((v: any) => ({ pullups: v.pullups, pushups: v.pushups, squats: v.squats }))
                    }
                },
            ]).then(async () => {
                await RESTClient.from("snapshot").delete().gt("id", 0);
                setDisabled(prev => ({ ...prev, finish: true }));
                setSets([]);
                setFormSets(0);
                toast({
                    title: "Entranamiento finalizado.",
                    status: "success",
                    duration: 5000,
                    isClosable: true
                })
            });
    }

    useEffect(() => {
        getSnapshot();
    }, []);

    useEffect(() => {
        setDisabled(prev => ({ ...prev, setDown: false }));
    }, [formSets]);

    return (
        <React.Fragment>
            <Flex padding={4} gap={4}>
                <Flex alignItems="center" justifyContent="space-between" gap={4}>
                    <Text fontWeight={200}>Series</Text>
                    <NumberInput maxW={150} min={0} max={50} value={formSets} onChange={(e) => setFormSets(Number(e))}>
                        <NumberInputField />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </Flex>
                <Button flex={1} backgroundColor="#00e975" color="#fff" isDisabled={disabled.setDown} onClick={displayDownSetsOnPage}>
                    <FontAwesomeIcon icon={faArrowDown} />
                </Button>
                <Button flex={1} backgroundColor="red.400" color="#fff" isDisabled={disabled.finish} onClick={handleFinish}>
                    <FontAwesomeIcon icon={faFlagCheckered} />
                </Button>
            </Flex>

            <Divider />

            <Timer />

            <Divider />

            <SimpleGrid spacing={4} padding={4} height="79vh" overflowY="scroll">
                {sets.map((v: any, index: number) => (
                    <Card
                        key={index + 1}
                        variant="outline"
                        height="fit-content"
                        onClick={() => handleOpenModal({ ...v, index: index + 1 })}
                    >
                        <CardBody padding={4}>
                            <Flex marginBottom={4} justifyContent="space-between" alignItems="center">
                                <Text fontWeight="bold">Serie {v.set}</Text>
                                <Badge variant="subtle" colorScheme="green" paddingY={1} paddingX={2}>Completada</Badge>
                                {/* <Badge variant="subtle" colorScheme="red" paddingY={1} paddingX={2}>No completada</Badge> */}
                            </Flex>
                            <Flex justifyContent="space-between" alignItems="flex-end">
                                <Box>
                                    <Text fontSize={12}>Dominadas: {v.pullups}</Text>
                                    <Text fontSize={12}>Flexiones: {v.pushups}</Text>
                                    <Text fontSize={12}>Sentadillas: {v.squats}</Text>
                                </Box>
                                {/* <Text fontSize={14}>3 min</Text> */}
                            </Flex>
                        </CardBody>
                    </Card>
                ))}
            </SimpleGrid>
            <FormModal isOpen={isOpen} onClose={onClose} onSave={handleSaveModal} data={modalData} />
        </React.Fragment>
    );
};
